export default {
  connection_failed: {
    oops_connection: 'Oops, connection failed!',
    looks_like_you:
      'Looks like you need to check your internet connection and try again.',
  },

  media: {
    whoops_looks_like:
      "Whoops... Looks like your browser is outdated or doesn't support video chat. Upgrade your current browser to the newest version or install a new one.",
    got_it: 'Got it',
  },

  rate: {
    rate_system: 'Rate system',
    it_is_your_rate:
      'It is your rate. It affects number of coins that you will get in private calls. For example, if your rate is 60/120, you will recieve 60 coins for the first second of private call and 120 coins every minute starting from 61 second onwards.',
    your_rate_depends:
      'Your rate depends on number of full minutes that you will spend in calls. It doesn’t matter if it will be in random call or in private call.',
    rate_is_calculated:
      'Rate is calculated as a sum of full minutes spent in calls for last 7 work days, excluding today. Rate is recalculated each day at 00.00 UTC.',
  },

  profile_rate: {
    third_block_title: '🎁 Bonus coins',
    third_block_subtitle: 'Complete the tasks and get coin rewards',
    second_block_title: 'Random call',
    first_block_title: 'Private call',
    first_block_first_option: '1-60 second',
    first_block_second_option: 'Next minutes',
    second_block_first_option: '20-60 second',
    second_block_second_option: 'Next minutes',
    claim: 'Claim',
  },

  streamer_task_modal: {
    toaster_title: '{amount} bonus coins claimed',
  },

  days: 'Days',
  hours: 'Hours',
  claimed: 'Claimed',
  claim: 'Claim',
  bonus: 'Bonus',
  seconds: 'Seconds',
  user_banned: 'User banned',
  create_new_password: 'Create your new password',
  repeat_new_password: 'Repeat your new password',
  repeat_password: 'Repeat password',
  password_updated: 'The password was updated',
  // 404
  oops_this_page_doesnt_exist: "Oops! This page doesn't exist",
  dont_worry_you_can_return_to_the: "Don't worry, you can return to the",
  homepage: 'homepage',
  whoops_critical_error: 'Whoops! Critical error',
  not_to_worry_you_can_head_over_to_our:
    'Not to worry. You can head over to our',

  // onboarding
  if_you_do_not_have_an_account: 'If you do not have an account',
  if_you_have_an_account: 'If you have an account',
  register: 'Register',
  login: 'Login',
  email_address: 'Email address',
  email_address_placeholder: 'Input your email',
  forgot_password: 'Forgot password?',
  reset_password: 'Reset password',
  reset_password_subtitle:
    "Please enter your email.  We'll send you a link\n to reset your password",
  link_sent_title: '🎉 Link successfully sent',
  link_sent_subtitle: 'Reset link is sent to',
  link_sent_subtitle_primary: 'Please, check your inbox to reset the password',
  create_your_account: 'Create your account',
  next: 'Next',
  welcome_back: 'Welcome back',
  log_in: 'Log in',
  you_rate: 'Your rate',
  do_not_have_an_account: 'Do not have an account?',
  introduce_yourself: '👧 Introduce yourself...',
  before_you_continue: '✋ Before you continue...',
  i_am_ready: 'I am ready',
  email: 'Email',
  example_mail_com: 'example@mail.com',
  password: 'Password',
  min_characters_6: 'Min characters: 6',
  your_name: 'Your name',
  contains_latin_charaсters_only: 'Contains Latin charaсters only',
  your_agency_id: 'Your agency ID',
  input_id: 'Input ID',
  id: 'ID',
  passport: 'Passport',
  have_your_passport_ready: 'Have your passport ready',
  good_lighting: 'Good lighting',
  make_sure_you_are_in_a_well_place: 'Make sure you are in a well place',
  selfie: 'Selfie',
  be_ready_for_a_selfie: 'Be ready for a selfie',
  your_data_is_safe_with_us: '🔒 Your data is safe with us',
  were_going_to_try_and_match_your:
    'We’re going to try and match your document to your picture to make sure it’s you. We won’t keep a copy of your picture or your document.',
  continue: 'Continue',
  get_ready_to_take_selfie_with_your_passport:
    '📸 Get ready to take selfie with your passport?',
  were_going_to_take_the_photo_of_your_face:
    'We’re going to take the photo of your face with passport to make sure it’s you',
  allow_access_to_your_camera: 'Allow access to your camera and microphone',
  hiti_requires_access_to_your_camera:
    'Hiti requires access to your camera and microphone so that other people can see and hear you. Click “Accept” to start chatting',
  allow_access: 'Allow access',
  use_your_microphone: 'Use your microphone',
  use_your_camera: 'Use your camera',
  block: 'Block',
  allow: 'Allow',
  be_sure_to_allow_access_to_the_camera:
    'Be sure to allow access to the camera and microphone',
  email_incorrect: 'Email incorrect',
  min_password_length_is_6_and_max_is_30:
    'Min password length is 6 and max is 30',
  name_is_incorrect_only_a_z_and:
    'The Name field can only contain letters (A-z)',
  min_name_length: 'Min Name length is 3',
  max_name_length: 'Max Name length is 127',
  example: 'Example',
  take_the_photo: 'Take the photo',
  take_photo: 'Take photo',
  retake: 'Retake',
  confirm: 'Confirm',
  upload_the_photo_of_your_passport: '📸 Upload the photo of your passport',
  your_passport: '📸 Your passport',
  were_going_to_take_the_photo:
    'We’re going to take the photo of your passport to identify you',
  upload_from_computer: 'Upload from computer',
  take_a_picture: 'Take a picture',
  when_is_your_birthday: 'When is your birthday? 🎂',
  you_must_be_at_least_18_years_old_age_must_match:
    'You must be at least 18 years old. Age must match your passport age. Your age will be verified by the moderator',
  birthday: 'Birthday',
  choose_the_date_of_your_birthday: 'Choose the date of your birthday',
  you_must_be_at_least_18_years_old: 'You must be at least 18 years old',
  very_old: 'Very old',
  upload_profile_photo: 'Upload profile photo',
  add_at_least_2_photos_to_continue: 'Add at least 2 photos to continue',
  photo_rules_recommendations: 'Photo rules & Recommendations',
  avatar_requirements: 'Avatar requirements',
  no_nude_photos: 'No <span class="color-blupurp">nude</span> photos',
  take_high_quality_and_attractive: 'Take high quality and attractive',
  show_your_face_clearly:
    'Show your <span class="color-blupurp">face</span> clearly',
  dont_use_other_fake_photos:
    '<span class="color-space-red">Don’t use</span> other fake photos',
  dont_use_photos_with_babies:
    '<span class="color-space-red">Don’t use</span> photos with babies or other people',
  upload: 'Upload',
  loading: 'Loading',
  profile_with_stories_earns: 'Profile with Stories earns',
  more: 'More',
  on_moderation: 'On moderation',
  upload_your_real_video_with:
    'Upload your real video with a clearly visible face. Make it catchy and beautiful.',
  upload_your_first_stories: 'Upload your first stories',
  show_off_your_best_video:
    'Show off your best video. Get feedback, likes and be a part of a growing community',
  make_stories: 'Make stories',
  upload_stories: 'Upload stories',
  skip: 'Skip',
  skip_step: 'Skip step',
  record_a_short_video: 'Record a short video',
  girls_with_stories_earns: 'Girls with Stories earns 💸',
  upload_from_gallery: 'Upload from gallery',
  record_your_real_video_with:
    'Record your real video with a clearly visible face',
  start_recording: 'Start recording',
  max_duration: 'Max duration',
  stop_recording: 'Stop recording',
  recording: 'Recording...',
  post_your_video_story: 'Post your video story?',
  create_new: 'Create new',
  post_video: 'Post video',
  upload_new: 'Upload new',
  oops_something_went_wrong: 'Oops... Something went wrong',
  please_try_again_to_upload: 'Please try again to upload the photo or upload',
  new: 'new',
  i_see: 'I see',
  too_big_file_max_size: 'Too big file. Max size - 30Mb',
  please_upload: 'Please upload',
  file: 'file',
  too_long_video_max_duration: 'Too long video. Max duration - 15 seconds',
  random_call: 'Random call',
  this_is_a_public_conversation:
    'This is a public conversation. You must follow the public call rules',
  private_call: 'Private call',
  this_is_a_private_conversation:
    'This is a private conversation. You can express yourself how you want',

  our_rules: 'Our rules',
  in_the_first_30_seconds_of_calls:
    'In the first 30 seconds of calls you can not make any sexual gestures and nudity',
  in_a_random_call_you_can_not_make:
    'In a random call you can not make any sexual gestures and nudity',
  no_sexual_and_nude_photos: 'No sexual and nude photos in your profile',
  if_you_do_any_of_these:
    'If you do any of these, your account will be suspended with penalty',
  i_agree: 'I agree',

  // Chat
  no_dialogs_yet: 'No dialogs yet',
  chat_will_be_created_after_every_video_call:
    'Chat will be created after every video call',
  lets_cideo_chat: 'Let’s Video Chat',
  online_only: 'Online only',
  recent: 'Recent',
  my_fans: 'My fans',
  unread: 'Unread',
  favourites: 'Favourites',
  call_history: 'Call history',
  people_that_called_earlier: 'People that called earlier',
  today: 'Today',
  yesterday: 'Yesterday',
  select_the_dialogue: 'Select the dialogue',
  to_continue_the_communication: 'to continue the communication',
  online: 'Online',
  report: 'Report',
  block_user: 'Block user',
  delete_dialog: 'Delete dialog',
  type_a_message: 'Type a message...',
  sending_message: 'Sending message is unavailable',
  unavailable_until_user:
    'Unavailable until user reply to your message or add to favorites',
  no_messages_here_yet: 'No messages here (yet)',
  say_hi_or_send_a_gift: 'Say "Hi" or send a gift to start a dialogue',

  // Chat delete
  are_you_sure_you_wont_be_able_to:
    'Are you sure? You won’t be able to restore it.',
  yes_please: 'Yes, please',
  no_thanks: 'No, thanks',

  // Chat report
  choose_one_variant: 'Choose one variant',
  send_report: 'Send report',
  rudeness_or_abuse: 'Rudeness or abuse',
  spam_or_scam: 'Spam or scam',
  nudity_or_pornography: 'Nudity or pornography',
  false_gender: 'False gender',
  fraud: 'Fraud',
  verbal_harassment: 'Verbal harassment',
  underage: 'Underage',

  // Chat block
  block_user_name: 'Block user {name}?',
  name_wont_be_able_to_call_or_message_you:
    '{name} won’t be able to call or message you. You can unblock {name} go to Profile -> Black list',
  user_name_is_blocked_you_can_unblock_name_go:
    'User {name} is blocked. You can unblock {name} go to Profile -> Black list',
  done: 'Done',

  // footer
  online_video_chat: 'Online video chat',
  footer_company_address: 'Nicosia, Cyprus.',
  we_assept: 'We accept',
  privacy_policy: 'Privacy Policy',
  cookie_policy: 'Cookie Policy',
  terms_and_conditions_of_use: 'Terms and conditions of Use',
  purchase_and_subscription_terms: 'Purchase and Subscription Terms',
  subscription_terms: 'Subscription Terms',
  purchase_terms: 'Purchase Terms',

  // media
  allow_access_to_your_camera_and_microphone:
    'Allow access to your camera and microphone',
  mili_asks_for_access_to_your_camera_and_microphone:
    'Mili asks for access to your camera and microphone, so other users can see and hear you well. Click',
  and_start_chatting: 'and start chatting',
  access_to_your_camera_fas_forbidden_refresh_the_page_and_try_again:
    'Access to your camera was forbidden. Refresh the page and try again. If the error message remains, then you must manually enable your camera for the site in your',
  browser_settings: 'browser settings',
  got_it: 'Got it',
  whoops_looks_like_your_browser_is_outdated:
    "Whoops... Looks like your browser is outdated or doesn't support video chat. Upgrade your current browser to the newest version or install a new one.",

  // Doubles
  hiti_live_page_is_already_open:
    'hiti.live page is already open in your browser or another device.',
  switch_here: 'Switch here',

  // menu
  video_chat: 'Video chat',
  messages: 'Messages',

  // Profile
  stories: 'Stories',
  coins_history: 'Coins history',
  userId: 'User ID',
  hostId: 'Host ID',
  settings: 'Settings',
  info_rules: 'Info / Rules',
  description_on_profile_page: 'Description on profile page',
  input_some_description: 'Input some description about yourself',
  save: 'Save',
  photos: 'Photos',
  avatar: 'Avatar',
  set_as_avatar: 'Set as avatar',
  delete: 'Delete',
  tap_to_upload: 'Tap to upload',
  are_you_sure_you_want_to_delete: 'Are you sure you want to delete?',
  upload_file: 'Upload file',

  // RequestWithdrawal
  go_to_history: 'Go to history',
  available_to_request: 'Available to request',
  coins: 'Coins',
  revenue: 'Revenue',
  email_connected_to_your_binance: 'Email connected to your Binance',
  your_binance_id: 'Your Binance ID (BUID)',
  buid_should_not_be_empty: 'Binance ID should not be empty',
  buid: 'BUID',

  min_buid_len: 'Minimal binance id length is',
  max_buid_len: 'Max binance id length is',

  input_email: 'Input email',
  request_withdrawal: 'Request withdrawal',
  request_withdrawal_by_the_end_of_the_day:
    '🚨 Request withdrawal by the end of the day on <b>Wednesday</b> to receive your payment on <b>Thursday</b> of the same week',
  the_minimum_withdrawal_amount_is:
    'The minimum withdrawal amount is ${amount}. Withdrawal is not possible until you earn the minimum amount.',
  only_available_if_you_work_with_agency:
    'Only available if you work with agency',
  choose_withdrawal_method: 'Choose withdrawal method',
  wire: 'Wire',
  payment_card: 'Payment card',
  binance: 'Binance',
  paypal: 'PayPal',

  every_n_s: 'every {seconds}s',

  // Random
  want_to_find: 'Want to find a men to chat to?',
  start_video_chat_now: 'Start Video Chat Now',
  end_chat: 'End chat',
  searching_for_people: 'Searching for people...',
  user_found_connecting: 'User found! Connecting...',
  me: 'Me',
  show: 'Show',
  hide: 'Hide',

  // Private
  youll_be_charged_120_coins_per_minute:
    'You’ll be charged 120 coins per minute',
  calling: 'Calling...',
  is_busy_now: 'is busy now:(',
  he_will_call_you_back: 'He will call you back as soon as he is available ',
  she_will_call_you_back: 'She will call you back as soon as she is available ',
  oops_not_enough_coins: 'Oops, not enough coins',
  private_call_will_cost_120:
    'Private call will cost 120 coins/min, please purchase coins',
  get_coins: 'Get coins',

  private_call_modal: {
    income_title: 'Waiting for your response...',
    outcome_title: 'Calling...',
    my_rate: 'My rate',
    first_minute: 'First minute',
    each_next_minute: 'Each next minute',
    you_have_n: 'You have {seconds}s to answear',
  },

  // Stories
  the_image_is_load_with_errors: 'The image is load with errors',
  new_story: 'New story',
  no_stories_yet: 'No stories yet',
  create_first_stories_and_show:
    'Create the first stories and show yourself, emotions and moments. Get be famous.',
  are_you_sure_to_delete_story: 'Are you sure you want to delete stories?',
  cancel_story: 'Cancel',
  delete_story: 'Delete',
  requested_withdrawal: 'Requested withdrawal',
  in_progress: 'In progress',
  you_have_requested_withdrawal_to: 'You have requested withdrawal to',
  account: 'account',
  card_number: 'Card number',
  input_card: 'Input card',
  card_number_incorrect: 'Card number incorrect',
  are_you_sure: 'Are you sure?',
  do_you_agree_to_request_withdrawal:
    'Do you agree to request withdrawal? You will receive the full amount at the time of accrual',
  cancel: 'Cancel',
  request: 'Request',
  go_to_withdrawal: 'Go to withdrawal',
  date: 'Date',
  status: 'Status',
  amount: 'Amount',
  withdrawn: 'Withdrawn',
  cancelled: 'Cancelled',
  there_is_no_history_yet: 'There is no history yet',
  withdraw_to: 'Withdraw to',

  // Rate
  go_to_statistics: 'Go to statistics',
  go_to_rate_matrix: 'Go to rate matrix',
  your_rate: 'Your rate',
  your_private_call_rate: 'Your private call rate',
  next_rate: 'Next rate ',
  minutes: 'minutes',
  minutes_in_videochat: 'Minutes in videochat',
  first_second: 'First second',
  first: 'First',
  twenty_seconds: '20 seconds',
  first_twenty_seconds: 'First 20s',
  every_minute: 'Every minute',
  first_20sec: 'First 20sec',
  available_day_off: 'Available day off',
  dayoff: 'Day off',
  notwork: 'Did not work',
  total7days: 'Total 7 days',
  day: 'Day',
  minutes_worked: 'Minutes worked',
  day_offs: 'Day offs',
  for_every_five_work_days:
    'For every five work days, you’ll gain two days off. These days can be saved, up to a maximum of 30 days off. You can use them by not spending any second in video chat for one day by UTC time.',
  days_off_will_allow:
    'Days off will allow you to maintain your rate, and not affect your statistics. For example if you will have day off during 7 calendar days, the minutes for the 8 day will be included in the calculation of rate.',
  so_7_work_days:
    'So 7 work days will be used to calculate your rate and a day off is not a work day.',
  first_14_days_after_registration:
    'First 14 days after registration your rate will be 60/120, after  it will be calculated based on the amount of minutes spent in videochat',
  profile_is_moderated: 'Profile is being moderated',
  upload_new_photos: 'Upload new photos',
  moderation_failed: 'Moderation failed, try again',

  // profile - statistic
  profile: {
    revenue: 'Revenue',
    coins: 'Coins',
    statistic: 'Statistic',
    withdrawal: '💰 Withdrawal',
    details: 'ℹ️ Details',
  },
  //coins-history
  coin_history: {
    you_have_no_buy_any_coins:
      'You have not buy any coins yet. When you buy the coins, they will appear here.',
  },
  admin_addition: 'Admin addition',
  admin_subtraction: 'Admin substraction',
  email_confirmed: 'Email Confirmed',
  free_coins: 'Free coins',
  gift_got: 'Gift',
  gift_sent: 'Gift sent',
  host_enroll: 'Random call',
  host_enroll_private: 'Private call',
  message_opened: 'Message opened',
  message_opened_photo: 'Message opened photo',
  message_opened_photo_streamer_payment:
    'Message opened photo streamer payment',
  message_opened_streamer_payment: 'Message opened streamer payment',
  message_opened_video: 'Message opened video',
  message_opened_video_streamer_payment:
    'Message opened video streamer payment',
  message_unlocked: 'Message unlocked',
  paid_daily_reward: 'Paid daily reward',
  private_call_init: 'Private call accept',
  private_call_recurring: 'Private call',
  purchase: 'Purchase',
  recurring_subscription: 'Subscription',
  recurring: 'Recurring',
  referral_streamer_percent: 'Coins from referral',
  refund: 'Refund',
  streamer_penalty: 'Penalty',
  thread_unlocked: 'Thread unlock',
  video_ad_reward: 'Ad reward',
  weekly_coins_write_off: 'Weekly coins write off',
  withdraw_write_off: 'Withdraw',
  welcome: 'Welcome',
  type: 'Type',
  user: 'User',
  // streamer

  profile_is_being_moderated: 'Profile is being moderated',
  all_photos_not_pass_moderation: "All photos didn't pass moderation",
  you_not_pass_moderation: "You didn't pass moderation",
  try_again: 'Try again',
  verified: 'Verified',
  edit_profile: 'Edit Profile',
  // Call history

  in_call: 'In call',
  // user delete modal
  delete_my_account: 'Delete my account',
  delete_cancel: 'Cancel',
  //logout modal
  oh_no_you_are_leaving_are_you_sure: 'Oh no! You are leaving... Are you sure?',
  yes_im_leaving: 'Yes, I’m leaving',
  no_im_staying: "No, I'm stay",

  delete_profile: 'Delete profile',
  log_out: 'Log Out',
  withdrawal: 'Withdrawal',
  upload_video: 'Upload video',
  you_didnot_call_anyone_yet: "You didn't call anyone yet...",
  your_profile_on_moderation: 'You profile sent to the moderation process',

  // streamer

  your_photos_send_to_moderation: 'You photos sent to the moderation process',
  are_you_sure_that_you_want_exit:
    'Are you sure you want to exit? Story will be deleted',
  close: 'Close',
  agency_id_is_only_numbers_and_not_empty:
    'Agency ID incorrect only numbers, and not be empty!',
  take_a_photo: 'Take a photo',
  invite_girls_to_get_coins: 'Invite girls to get coins for free!',
  invitation_link: 'Invitation link',
  attention: 'Attention!',
  spamming_block:
    'In case of mass spamming on social networks, your account will be blocked forever!',
  coins_table: 'Coins table',
  credited_to_you: 'Credited to you',
  if_girl_earns: '🤑 If girls earns',
  you_get: 'you get',
  copy: 'copy',
  copied: 'copied',

  pick_month: 'Pick month',
  pick_week: 'Pick week',
  pick_range: 'Pick range',

  current_month: 'Current month',
  current_week: 'Current week',

  jan: 'Jan',
  feb: 'Feb',
  mar: 'Mar',
  apr: 'Apr',
  may: 'May',
  jun: 'Jun',
  jul: 'Jul',
  aug: 'Aug',
  sep: 'Sep',
  oct: 'Oct',
  nov: 'Nov',
  dec: 'Dec',

  tier_one_coins: '2750 coins',
  tier_two_coins: '5000 coins',
  tier_three_coins: '10000 coins 🔥',

  // friends
  request_sent: 'Request sent',
  now_you_are_friends: 'Now you are friends!',
  youve_received_friend_request: 'You’ve received friend request',
  friend_requests: 'Friend requests',
  ignore: 'Ignore',
  ignore_all: 'Ignore all',
  remove_from_friends: 'Remove from friends',
  accept: 'Accept',
  name: 'Name',
  you_can_chat_only_with_friends: 'You can chat only with friends',
  wait_until_they_accept_request_for_friends:
    'Wait until user accept your friend request',
  avatar_cannot_be_removed: 'Avatar cannot be removed!',

  referral_streamer_percent_add: 'Refferal Income',
  referral_streamer_start_work_bonus: 'Refferal Start Bonus',
  referral_streamer_first_payoff_bonus: 'Refferal First Payout Bonus',
  referral_streamer_net_earn_bonus: 'Refferal Payout Bonus',
  referral_streamer_percent_sub: 'Refferal Admin Substraction',

  penalty_default: 'Don’t look at Camera',
  penalty_primary: 'No Face',
  penalty_secondary: 'Nude in call',
  penalty_ternary: 'Stranger in call',
  other_reason: 'Other reason',

  referral_system: 'Referral system',
  open_in_application: 'Open in application',
  get_best_mobile_experience: 'Get best mobile experience',
  download: 'Download',
  error_occurred:
    'An error occurred while processing your request. Please try again later.',

  balance: 'Balance',
  delete_this_dialog: 'Delete this dialog?',

  card_holder_name: 'Cardholder name',
  card_holder_name_required: 'Cardholder name is required',

  details: 'ℹ️ Details',

  card_holder_name_only_contains_latin_symbols:
    'Cardholder name  must contain only Latin characters',
  input_card_holder_name: 'Input cardholder name',
  max_card_holder_name_length: 'Max cardholder name length is 50 symbols',
  card_holder_name_has_first_and_last_name:
    'Cardholder name must include both first and last name',
  only_numbers: 'Numbers only',
  referral_streamer_add: 'Refferal Income',
  minimum_for_withdrawal: 'minimum for withdrawal',
  follow_rules: 'Follow rules',
  express_yourself: 'Express yourself',
  referral: {
    reports_title: 'Reports',
    reports_table_empty_state: {
      title: 'There are no created reports',
      subtitle: 'Create your first report',
    },
    reports_error:
      'An error occurred. Please try again or contact support if the issue persists',
    reports_button: 'Create report',
    title: '🎁 Referral system',
    date: 'The date is based on UTC+0 time zone',
    banned: 'Banned',
    copied: 'Copied',
    last_online: 'Last online:',
    tabs: {
      first: 'Payments',
      second: 'Statuses',
      third: 'Reports',
    },
    menu_tabs: {
      first: 'All',
      second: 'Banned',
      third: 'New',
    },
    analytics: '📊 Analytics',
    copy_title: '🎁 Invite new hosts',
    copy_subtitle: 'Share link to invite others and earn free coins',
    button_copy: 'Copy link',
    button_copied: 'Copied',
    get_info: 'Info',
    hosts: 'hosts',
    last_week: 'last 7 days',
    last_month: 'last 30 days',
    open_calendar: 'open calendar',
    last_three_month: 'last 90 days',
    earned: 'Earned',
    search_by_email_or_id: {
      placeholder: 'Search',
    },
    invite_button: 'Invite',

    not_invited_error_title: 'There are no invited hosts yet',
    not_invited_error_description: 'Invite first hosts',
    not_found_error: 'User was not found',

    statuses_block: {
      total_coins: 'My total coins',
      host_total_coins: 'Hosts’ total coins',
      current_statuses: 'Current statuses',
      new_hosts_legend: 'Host who was registered last 1-14 days',
      verified_hosts_legend: 'Host who has passed moderation',
      verification_hosts_legend: 'Host on the moderation process',
      banned_hosts_legend: 'Host who was banned',
      hosts_total_coins_legend: 'All coins that hosts earned for chosen period',
      my_total_coins_legend:
        "Coins you got after the host's withdrawal of coins",
    },
    host_coins_table: {
      title: 'Coins',
    },
    host_minutes_table: {
      title: 'Minutes in calls',
    },
    host_minutes_chart: {
      title: 'Minutes in video chat',
    },
    host_coins_chart: {
      title: 'Host’s total coins',
    },
    empty_state_title: 'There is no data for this period',
    empty_state_description: 'Please change time frame',
  },
  referral_reports_limit_modal: {
    title: 'You reached your daily limit',
    subtitle: 'You can generate {limit} reports a day. Try tomorrow',
    button: 'Got it',
  },
  referral_streamer_info_modal: {
    total_coins_block: {
      my_total_coins: 'My total coins',
      host_total_coins: 'Host’s total coins',
      private_coins: 'Coins in private',
      random_coins: 'Coins in random',
      gift_coins: 'Coins in gift',
    },
  },
  referral_create_report_modal: {
    title: 'Create detailed report',
    subtitle: 'The report will be generated based on the selected dates',
  },
  referral_legend_modal: {
    title: 'Statuses',
    subtitle: 'Current host’s status on the platform',
    new_hosts_legend_title: 'New hosts',
    new_hosts_legend_description: 'Host who was registered last 1-14 days',
    verified_hosts_legend_title: 'Verified hosts',
    verified_hosts_legend_description: 'Host who has passed moderation',
    verification_hosts_legend_title: 'Hosts on verification',
    verification_hosts_legend_description: 'Host on the moderation process',
    banned_hosts_legend_title: 'Banned hosts',
    banned_hosts_legend_description: 'Host who was banned',

    hosts_total_coins_title: 'Total earned coins',
    hosts_total_coins_description:
      'All coins that hosts earned for chosen period',
    my_total_coins_title: 'My total coins',
    my_total_coins_description:
      "Coins you got after the host's withdrawal of coins",

    support_title: 'Need a hand with analytics',
    support_button: 'ℹ️ View detailed tutorial',
  },
  referral_details: {
    earning: '📸 Earning as a streamer',
    coins: 'Coins',
    dollars: 'Dollars',
    commission: '🎁 Сommission from refferals',
    the_commission:
      'The commission percentage is calculated based on the amount of coins of the last referrals withdrawal',
  },
  streamer_rating_legend_modal: {
    title: 'Rating',
    subtitle:
      'The rating is an average score given by users after conversing with you in a call.',
    maximum_score:
      "The maximum rating is 5,0. A rating of 4,0 to 5,0 is a good indicator of your performance. This means that users are satisfied with your communication, and you'll get more user traffic.",
    girl_score:
      'Monitor your rating regularly, as dropping to 3,6 or below may get your account banned',
  },
  video_call_user_suspected_background: {
    title: 'No Sex gestures, talks and content',
    subtitle: 'Do not tell that you work here',
  },
  rate_info_about_minutes_modal: {
    full_minutes:
      'This is the sum of full minutes earned <b>today</b> and the previous <b>6 working days</b>, which will be used to determine your level <b>TOMORROW</b>',
    minutes_to_next_lvl:
      'This is the number of full minutes required to move to the <b>next level</b>',
  },
  video_call_rate: {
    rate_your_conversation: 'Rate your conversation',
    with: 'with',
    help_us_understand: 'Help us understand what went wrong?',
    tell_what_you_didnt: 'Tell what you didn’t like',
    what_was_good: 'What was good about this call?',
    tell_what_you_liked: 'Tell what you liked',

    send_feedback: 'Send feedback',
  },

  video_call_rate_confirm: {
    thanks_for: 'Thanks for your feedback',
    your_feedback: 'Your feedback helps to create better experience',
    glorious: 'Glorious!',
  },

  gifts_modal: {
    free_send: 'Send a free gift to the user you want to receive',
    for_get: 'For received gifts from the user you will get coins',
  },

  banned: {
    title: 'You have been banned for “{reason}”',
    subtitle: "You'll be able to use website in:",
    available: 'Available in: {date}',
    subtitle_secondary:
      'If you think that you have been blocked unfairly, please write to',
    never: 'Never',
    footer:
      'The count of bans is limited. In case of systematic violation, your account will be banned forever',
    id: 'My ID: {streamerId}',
    copied: 'Your ID is copied',
  },
  important_announcement: 'Important announcement',
  cab_header_streamer_task_plate: {
    title: 'Bonus coins',
  },
  hour: 'hour',
  weekly_top_streamer: 'Weekly Top Streamer',
  weekly_top_streamer_page: {
    activity_rules: 'Activity rules',
    place_prize: '4-10th place - 15 000 coins prize',
    prize: 'Prize',
    conditions_title: '🏆 Weekly Top Streamer',
    conditions_subtitle: 'Earn coins and get a chance to win coin prizes',
    conditions_text: 'The week will end in',
  },
  weekly_top_streamer_activity_rules_modal: {
    title: '🏆 Weekly Top Streamer',
    subtitle: '🥇 Rewards (in coins)',
    list_option_first: '1 st place - {reward}',
    list_option_second: '2nd place - {reward}',
    list_option_third: '3rd place - {reward}',
    list_option_fourth: '4-10th place - {reward}',

    rules_title: '🧩 Rules',
    ol_list_option_first:
      'The ranking period is updated every week. The week starts at 4:00AM UTC on Monday and ends at 4:00AM UTC next Monday',
    ol_list_option_second:
      'During the week, all coins from private calls, matches and prolongs will be counted as "stars". 1 coin = 1 star. Coins for gifts and bonus coins will NOT be counted as stars',
    ol_list_option_third:
      'All ranking lists will be determined by the number of stars from high to low',
    ol_list_option_fourth:
      'Call coins must not be obtained through improper means. If we discover any improper conduct, all of those coins will be deducted and you will be disqualified for the activity',
    ol_list_option_fifth:
      'At the end of each week, rewards will automatically be credited to your coin balance',
  },
}
